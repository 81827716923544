import React from 'react';

const Error404Blog = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.4435 35.0945H5.67259C5.4797 35.0945 5.29477 35.0178 5.15838 34.8814C5.02199 34.745 4.94531 34.5601 4.94531 34.3672V13.1019C4.95299 12.0114 5.39162 10.968 6.16548 10.1996C6.93935 9.43116 7.98561 8.99997 9.07617 9H39.1854C40.2759 8.99997 41.3222 9.43116 42.0961 10.1996C42.8699 10.968 43.3085 12.0114 43.3162 13.1019V34.3672C43.3184 34.4763 43.296 34.5847 43.2507 34.684C43.2054 34.7832 43.1385 34.8709 43.0547 34.9407C42.9709 35.0105 42.8725 35.0607 42.7667 35.0874C42.6609 35.114 42.5504 35.1163 42.4435 35.0945ZM6.25444 33.6399H41.7163V13.1019C41.7086 12.3972 41.4231 11.7236 40.9221 11.228C40.421 10.7323 39.7446 10.4545 39.0398 10.4545H8.93075C8.22595 10.4545 7.54956 10.7323 7.04847 11.228C6.54739 11.7236 6.2621 12.3972 6.25444 13.1019V33.6399Z"
      fill="#475467"
    />
    <path
      d="M39.8256 32.4137H8.11667C7.92887 32.4062 7.75127 32.3263 7.62111 32.1907C7.49094 32.0551 7.41818 31.8744 7.41834 31.6864V12.3991C7.41818 12.2112 7.49094 12.0305 7.62111 11.8949C7.75127 11.7593 7.92887 11.6794 8.11667 11.6719H39.8256C40.0185 11.6719 40.2035 11.7486 40.3399 11.8849C40.4762 12.0213 40.5529 12.2063 40.5529 12.3991V31.6864C40.5529 31.8793 40.4762 32.0642 40.3399 32.2006C40.2035 32.337 40.0185 32.4137 39.8256 32.4137ZM8.84394 30.9592H39.0984V13.1264H8.84394V30.9592Z"
      fill="#475467"
    />
    <path
      d="M44.0728 38.2624H3.89826C2.8644 38.2624 1.87292 37.8514 1.14187 37.1204C0.410817 36.3893 0 35.3979 0 34.364C0 34.1711 0.0766781 33.9862 0.213068 33.8498C0.349458 33.7134 0.534388 33.6367 0.727273 33.6367H47.2727C47.4656 33.6367 47.6507 33.7134 47.7871 33.8498C47.9235 33.9862 48 34.1711 48 34.364C48 34.8784 47.8982 35.3877 47.7005 35.8626C47.5027 36.3374 47.2131 36.7683 46.848 37.1307C46.4829 37.493 46.0498 37.7799 45.5735 37.9741C45.0972 38.1683 44.5872 38.2663 44.0728 38.2624ZM1.57102 35.0913C1.72581 35.5878 2.03492 36.0219 2.45348 36.3306C2.87204 36.6393 3.37818 36.8067 3.89826 36.8079H44.0728C44.5918 36.8022 45.0958 36.6333 45.5135 36.3253C45.9312 36.0172 46.2413 35.5854 46.4 35.0913H1.57102Z"
      fill="#475467"
    />
    <path
      d="M12 16H18.4615M12 18.5714H31.3846M12 21.1429H26.7692M31.3846 28H36"
      stroke="#475467"
      strokeLinecap="round"
    />
  </svg>
);

export default Error404Blog;
